import Footer from '../components/Footer';

const Header = () => {
	return (
		<div className='bg-[#fcfcfc]'>
			<div className='flex flex-col z-50 h-5/6 w-full items-center justify-start mt-8'>
				<h1 className='mt-[100px] mb-[30px] font-pixel text-blue-900 animate-pulse'>
					CPLUS4
				</h1>
				<img
					src='/img/plus4.gif'
					className='w-[300px] rounded-md border-8 border-blue-900'
					alt=''
				/>
				<Footer />
			</div>
		</div>
	);
};

export default Header;
