import Header from './components/Header';

function App() {
	return (
		<div className='h-screen overflow-hidden'>
			<Header />
		</div>
	);
}

export default App;
