const Footer = () => {
	const year = new Date().getFullYear();
	return (
		<div className='flex flex-col items-center text-blue-900 mt-10'>
			<p className='font-stm text-[12px]'>{year} CPLUS4</p>
		</div>
	);
};

export default Footer;
